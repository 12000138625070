<template>
  <div>
    <div class="page_wrapper page-break">
      <div class="logo">
        <img src="../../assets/oinova/logo_oi.png" alt="logo Oi" />
      </div>
      <!-- /logo -->

      <div class="column">
        <div class="column_01">
          <div class="title">
            <h2>
              Oi, <span class="title_name">ANDREIA BARBOSA</span>, recebemos seu
              pedido pra pagamento da dívida
            </h2>
          </div>
          <!-- /title -->

          <div class="subtitle">
            <h3>
              Pra quitar sua dívida, é só pagar este boleto até a data de
              vencimento. Caso não ocorra o pagamento, o acordo será cancelado
              em até 7 dias corridos após o vencimento.
            </h3>
          </div>
          <!-- /subtitle -->
        </div>
        <!-- /column_01 -->

        <div class="column_02">
          <img
            src="../../assets/oinova/banner-top.png"
            width="100%"
            alt="banner"
          />
        </div>
        <!-- /column_02 -->
      </div>
      <!-- /column -->

      <div class="column_wrapper">
        <div>
          <h5>Vencimento</h5>
          <h4>{{ item.dueDate | dataF }}</h4>
        </div>

        <div>
          <h5>Valor</h5>
          <h4>R$ {{ item.installmentAmountCharged | dinheiro }}</h4>
        </div>
      </div>
      <!-- /column_wrapper -->

      <div class="bar_code">
        <svg id="barcode"></svg>
        <h4>{{ dados.digitableLine | linhadigi }}</h4>
      </div>
      <!-- /bar_code -->

      <div class="line"></div>

      <div class="information">
        <h2>Informações da negociação:</h2>

        <div class="information_item">
          <svg
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 7L9 17.5L5 13.5"
              stroke="#df2280"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <h4>
            Pessoa Física: <span>{{ item.document }}</span>
          </h4>
        </div>
        <!-- /information_item -->

        <div class="information_item">
          <svg
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 7L9 17.5L5 13.5"
              stroke="#df2280"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <h4>
            Produto: <span>{{ faturas[0].product }}</span>
          </h4>
        </div>
        <!-- /information_item -->

        <div class="information_item">
          <svg
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 7L9 17.5L5 13.5"
              stroke="#df2280"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <h4>
            Valor negociado: <span>R$ {{ item.totalValue | dinheiro }}</span>
          </h4>
        </div>
        <!-- /information_item -->
      </div>
      <!-- /information -->

      <div class="line"></div>

      <div class="information page-break-before">
        <h2>Débitos contemplados na negociação:</h2>

        <table class="information_table">
          <thead>
            <tr>
              <th>Nº da conta</th>
              <th>Referência</th>
              <th>Valor</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in faturas" :key="i">
              <td>{{ item.registerId }}</td>
              <td>{{ item.dueRef }}</td>
              <td>R$ {{ item.currentValue | dinheiro }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /information -->

      <div class="line"></div>

      <div class="information">
        <h2>Você pode pagar em:</h2>

        <div class="column_items">
          <div class="item">
            <img
              src="../../assets/oinova/internet_banking.png"
              width="40px"
              alt="internet_banking"
            />

            <p>Internet banking</p>
          </div>
          <!-- /item -->

          <div class="item">
            <img
              src="../../assets/oinova/caixa.png"
              width="45px"
              alt="internet_banking"
            />

            <p>Caixa eletrônicos</p>
          </div>
          <!-- /item -->

          <div class="item">
            <svg
              fill="#e31f86"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="40px"
              height="40px"
              viewBox="0 0 72 72"
              enable-background="new 0 0 72 72"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M30.489,66.511c-1.604-1.604-2.488-3.74-2.488-6.011V44L11.5,43.999C9.229,44,7.095,43.116,5.489,41.51
                            c-1.605-1.605-2.488-3.739-2.488-6.01C3,33.23,3.884,31.095,5.489,29.489c1.606-1.605,3.74-2.489,6.012-2.488L28,27l0.001-15.5
                            C28,9.23,28.884,7.095,30.489,5.489S34.23,3,36.5,3.001c2.27,0,4.404,0.883,6.011,2.489c1.605,1.605,2.489,3.74,2.488,6.011
                            L45,27.001h15.5c2.271,0,4.404,0.883,6.011,2.489c1.606,1.605,2.49,3.741,2.488,6.01c0.001,2.27-0.883,4.405-2.488,6.011
                            S62.77,44,60.5,43.999L45,44.001L44.999,60.5c0.001,2.271-0.883,4.404-2.488,6.011C40.905,68.116,38.77,69,36.5,68.999
                            C34.231,69.001,32.096,68.117,30.489,66.511z M31.415,40.586C31.776,40.948,32,41.447,32,42l0.001,18.5
                            c0,1.204,0.468,2.333,1.317,3.183C34.169,64.533,35.298,65,36.5,65c1.203,0,2.332-0.469,3.182-1.317
                            c0.851-0.851,1.319-1.979,1.317-3.182l0.002-18.5c0-1.104,0.895-2,2-2L60.5,40c1.204,0,2.332-0.468,3.182-1.317
                            C64.532,37.832,65,36.704,65,35.5c0-1.202-0.469-2.332-1.318-3.182c-0.849-0.85-1.979-1.318-3.18-1.316L43,31
                            c-1.104,0-2-0.895-2-1.999L40.999,11.5c0.001-1.202-0.468-2.332-1.316-3.182c-0.851-0.85-1.98-1.318-3.182-1.317
                            c-1.203,0-2.333,0.467-3.184,1.317c-0.85,0.85-1.316,1.979-1.317,3.182V29c0,1.104-0.896,2-2,2l-18.5,0.001
                            c-1.202,0-2.332,0.467-3.183,1.317C7.469,33.167,7.001,34.298,7,35.5c0,1.203,0.469,2.332,1.317,3.182
                            c0.851,0.851,1.98,1.318,3.183,1.317L30,40.001C30.553,40.001,31.053,40.225,31.415,40.586z"
                />
              </g>
            </svg>

            <p>Farmácias</p>
          </div>
          <!-- /item -->

          <div class="item">
            <svg
              width="40px"
              height="40px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="19" r="1.5" stroke="#e31f86" />
              <circle cx="17" cy="19" r="1.5" stroke="#e31f86" />
              <path
                d="M3.5 4H5.5L9.00446 15H17"
                stroke="#e31f86"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.22445 12.5L6.29862 6.5H18.8063C19.1476 6.5 19.3885 6.83435 19.2806 7.15811L17.614 12.1581C17.5459 12.3623 17.3548 12.5 17.1396 12.5H8.22445Z"
                stroke="#e31f86"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Supermercados</p>
          </div>
          <!-- /item -->
        </div>
        <!-- /column_items -->

        <p class="can_pay">
          Também é possível pagar em casas lotéricas apenas informando o número
          do telefone da dívida. O pagamento em lotérica tem taxas adicionais de
          R$ 1,82 sem o boleto e R$ 1,36 com o boleto, cobradas na próxima
          fatura, de acordo com a opção escolhida.
        </p>
      </div>
      <!-- /information -->

      <div class="line"></div>

      <div class="important">
        <h2>Importante:</h2>
        <p>A confirmação do pagamento pode levar até 5 dias úteis.</p>
        <p>
          O pagamento deste boleto ativa a negociação e gera o compromisso de
          pagar as demais parcelas.
        </p>
      </div>
      <!-- /important -->

      <div class="line"></div>

      <div class="expiry">
        <h5>
          Saiba mais em
          <a href="https://negociacao.oi.net.br/" target="_blank">Oi Negocia</a
          >.
        </h5>
      </div>
      <!-- /expiry -->

      <footer>
        <p>
          Obrigado por escolher a Oi. © Copyright 2019 - Oi.com.br - Todos os
          direitos reservados.
        </p>
        <hr />
        <p>A Oi respeita a sua privacidade e é contra o spam na rede.</p>
      </footer>
    </div>
    <!-- /page_wrapper -->
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import {api} from '@/conf/api'
export default {
  name: "BolOiParcelado",
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
    linhadigi: function (ld) {
      if (ld.length != 48) return ld;
    let cb1 = ld.substr(0, 11);
    let cb2 = ld.substr(12, 11);
    let cb3 = ld.substr(24, 11);
    let cb4 = ld.substr(36, 11);

    let d1 = ld.substr(11, 1);
    let d2 = ld.substr(23, 1);
    let d3 = ld.substr(35, 1);
    let d4 = ld.substr(47, 1);

    return cb1+'-'+d1+' '+cb2+'-'+d2+' '+cb3+'-'+d3+' '+cb4+'-'+d4
      
    }
  },
  data() {
    return {
      dados: null,
      item: null,
      faturas: null,
      nome: "",
    };
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: true, voltar: false });
    let resp = this.$store.state.divida_oi_nova_resp;
    // console.log(resp);
    this.dados = resp.resp.dados;
    this.item = resp.item;
    this.faturas = this.item.includedInvoices;
    this.nome = this.faturas[0].customerData.name;
    console.log(this.item, this.dados, this.faturas);
    //-----------------------------------------------------
   let divida_atual = this.$store.state.divida_atual
   let cgc_aqui = divida_atual.cpcgc
   let credor = ''
   if(cgc_aqui =='04164616000158') credor = 'OI MOVEL'
   if(cgc_aqui =='05423963000111') credor = 'OI TV DTH'
   if(cgc_aqui =='33000118000179') credor = 'OI FIXO'
   if(cgc_aqui =='33000118000190') credor = 'BRT - R2'

   let dados = {
      credor,
      cpf: divida_atual.cpcpfcgc,
      contrato: divida_atual.contrato,
      valor: this.item.installmentAmountCharged,
      venc: this.meuvenc,
      fatura: null,
      codBarras: this.dados.barCode,
      L_dig: this.dados.digitableLine,
      remessa: null,
      parcelas_faltam: null,
      email: null,
      valor_entrada: null,
      valor_parcela: null,
    };
    await api.post(`logs/gravaacordosomente`, dados);
   //-----------------------------------------------------
  },
  async mounted() {
    //JsBarcode("#barcode", "84620000002285000240101888831034976875040004");

    JsBarcode("#barcode", "84620000002285000240101888831034976875040004", {
      format: "ITF",
      width: 1.6,
      height: 80,
      margin: 0,
      textMargin: 2,
      font: "IBM Plex Mono",
      fontSize: 22,
      fontOptions: "500",
      color: "blue",
      displayValue: false
    });
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: monospace;
  line-height: 1.2;
}
.page_wrapper {
  width: 20cm;
  margin: 50px auto 0px auto;
}
.page_wrapper .line {
  margin-top: 30px;
  color: #dbdbdb;
  background-color: #dbdbdb;
  opacity: 1;
  width: 100%;
  height: 3px;
}
.logo {
  margin-bottom: 30px;
  display: block;
}
.title h2 {
  font-size: 26pt;
  color: #303030;
  font-weight: bold;
}
.title_name {
  font-size: 26pt;
  text-transform: uppercase;
}
.subtitle {
  margin-top: 20px;
  display: block;
}
.subtitle h3 {
  font-size: 18pt;
  font-weight: 500;
  color: #3f3f3f;
  letter-spacing: 1px;
}
.column {
  display: flex;
}
.column_01 {
  width: 60%;
}
.column_02 {
  width: 40%;
  display: flex;
  align-items: center;
}
.column_wrapper {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 20px;
}
.column_wrapper div {
}
.column_wrapper div h5 {
  color: #909090;
  font-weight: 400;
  font-size: 15pt;
}
.column_wrapper div h4 {
  color: #222222;
  font-size: 25pt;
}
.bar_code {
  margin-top: 30px;
  text-align: center;
}
.information {
  margin-top: 50px;
}
.information h2 {
  font-size: 17pt;
  color: #303030;
}
.information_item {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.information_item h4 {
  margin-left: 10px;
  font-size: 16pt;
  font-weight: normal;
  color: #222222;
}
.information_table {
  margin-top: 20px;
  width: 75%;
}
.information_table tbody {
  margin-top: 20px;
}
.information_table tr {
  height: 40px;
}
.information_table th {
  text-align: left;
  font-size: 13pt;
  color: #9b9b9b;
  font-weight: normal;
}
.information_table td {
  text-align: left;
  color: #222222;
  font-size: 14pt;
}
.column_items {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
}
.column_items .item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 50%;
}
.column_items .item p {
  margin-left: 10px;
  color: #2b2b2b;
  font-size: 18pt;
}
.can_pay {
  color: #222228;
  font-weight: normal;
  font-size: 16pt;
}
.important h2 {
  font-size: 17pt;
  color: #303030;
  margin-top: 30px;
}
.important p {
  margin-top: 20px;
  color: #222222;
  font-size: 17pt;
}
.expiry {
  margin-top: 20px;
}
.expiry p {
  color: #909090;
  font-size: 14pt;
  font-weight: 400;
}
.expiry p span {
  font-weight: bold;
  text-decoration: underline;
}
.expiry h5 {
  color: #222222;
  font-weight: 500;
  font-size: 18pt;
  margin-top: 20px;
}
.expiry h5 a {
  color: #dc2482;
  text-decoration: none;
}
footer {
  margin-top: 20px;
  padding: 3rem 0px;

  background-color: #f0f0f0;
}
footer p {
  font-size: 13pt;
  text-align: center;
  color: #909090;
}
footer hr {
  color: #d2d2d2;
  background-color: #d2d2d2;
  margin: 15px 0;
}
.page-break {
  page-break-after: avoid !important;
}
.page-break-before {
  padding-top: 50px;
  page-break-before: always;
}

@media print {
  @page {
    size: A4 portrait;
  }

  * {
    print-color-adjust: exact !important;
  }
}
</style>
